<template>
  <div id="terms">
    <p>
      These terms of service (“Terms”) cover your use and access to D &amp; S
      Trucking Solutions, Inc. d/b/a Glostone Trucking Solutions (“Glostone”)
      and PermitBook.com’s services, client software and websites ("Services”).
      By using our Services, you’re agreeing to be bound by these Terms. If
      you’re using our Services for an organization, you’re agreeing to these
      Terms on behalf of that organization.
    </p>

    <h2>Your Information and Permissions</h2>
    <p>
      When you use our Services, you provide us with things like your files,
      content, messages, contacts, and so on (“Your Information”). Your
      Information is yours. These Terms don’t give us any rights to Your
      Information except for the limited rights that enable us to offer the
      Services. You hereby grant us your permission to do things like hosting
      Your Information, backing it up, and sharing it when you ask us to.
    </p>

    <h2>Your Responsibilities</h2>
    <p>
      You’re responsible for your conduct. Your Information and you must comply
      with the Acceptable Use Policy below. Content in the Services may be
      protected by others’ intellectual property rights. Please don’t copy,
      upload, download, or share content unless you have the right to do so.
    </p>
    <br />
    <p>
      You are responsible for ensuring that Your Information contains electronic
      representations that meet the requirements and regulations of the various
      governing bodies and jurisdictions (USDOT, FMCSA, IFTA, state DOT’s,
      etc.).
    </p>
    <br />
    <p>
      Help us keep you informed and Your Information protected. Safeguard your
      password to the Services, and keep your account information current. Don’t
      share your account credentials or give others access to your account.
    </p>
    <br />
    <p>
      You may use our Services only as permitted by applicable law, including
      export control laws and regulations.
    </p>
    <p></p>
    <h2>Acceptable Use Policy</h2>
    <p>
      You agree not to misuse the Services or help anyone else to do so. For
      example, you must not even try to do any of the following in connection
      with the Services:
    </p>
    <ol>
      <li>probe, scan, or test the vulnerability of any system or network;</li>
      <li>
        breach or otherwise circumvent any security or authentication measures;
      </li>
      <li>
        access, tamper with, or use non-public areas or parts of the Services,
        or shared areas of the Services you haven't been invited to;
      </li>
      <li>
        interfere with or disrupt any user, host, or network, for example by
        sending a virus, overloading, flooding, spamming, or mail-bombing any
        part of the Services;
      </li>
      <li>
        access, search, or create accounts for the Services by any means other
        than our publicly supported interfaces (for example, "scraping" or
        creating accounts in bulk);
      </li>
      <li>
        send unsolicited communications, promotions or advertisements, or spam;
      </li>
      <li>
        send altered, deceptive or false source-identifying information,
        including "spoofing" or "phishing";
      </li>
      <li>
        promote or advertise products or services other than your own without
        appropriate authorization;
      </li>
      <li>circumvent storage space limits;</li>
      <li>sell the Services unless specifically authorized to do so;</li>
      <li>
        publish or share materials that are pornographic or indecent, or that
        contain acts of violence;
      </li>
      <li>
        advocate bigotry or hatred against any person or group of people based
        on their race, religion, ethnicity, sex, gender identity, sexual
        preference, disability, or impairment;
      </li>
      <li>
        violate the law in any way, including storing, publishing or sharing
        material that's fraudulent, defamatory, or misleading; or
      </li>
      <li>violate the privacy or infringe the rights of others.</li>
    </ol>

    <h2>Our Protected Information</h2>
    <p>
      The Services are protected by copyright, trademark, and other US laws.
      These Terms don’t grant you any right, title, or interest in the Services,
      others’ content in the Services, Glostone or PermitBook.com’s trademarks,
      logos and other brand features. We welcome feedback, but note that we may
      use comments or suggestions without any obligation to you.
    </p>

    <h2>Termination</h2>
    <p>
      You’re free to stop using our Services at any time. We reserve the right
      to suspend or terminate your access to the Services with notice to you if:
    </p>
    <ol>
      <li>you’re in breach of these Terms,</li>
      <li>
        you’re using the Services in a manner that would cause a real risk of
        harm or loss to us or other users, or
      </li>
      <li>
        you don’t have a Paid Account and haven't accessed our Services for 12
        consecutive months.
      </li>
    </ol>
    <br />
    <p>
      We’ll provide you with reasonable advance notice via the email address
      associated with your account to remedy the activity that prompted us to
      contact you and give you the opportunity to export Your Information from
      our Services. If after such notice you fail to take the steps we ask of
      you, we’ll terminate or suspend your access to the Services.
    </p>
    <br />
    <p>We won’t provide notice before termination where:</p>
    <ol>
      <li>you’re in material breach of these Terms,</li>
      <li>
        doing so would cause us legal liability or compromise our ability to
        provide the Services to our other users, or
      </li>
      <li>we're prohibited from doing so by law.</li>
    </ol>

    <h2>Discontinuation of Services</h2>
    <p>
      We may decide to discontinue the Services in response to unforeseen
      circumstances beyond Glostone or PermitBook.com’s control or to comply
      with a legal requirement. If we do so, we’ll give you reasonable prior
      notice so that you can export Your Information from our systems.
    </p>
    <p></p>
    <h2>Services “AS IS”</h2>
    <p>
      We strive to provide great Services, but there are certain things that we
      can't guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW, GLOSTONE,
      PERMITBOOK.COM AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO
      WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE SERVICES
      ARE PROVIDED "AS IS." WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY,
      FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
    </p>

    <h2>Limitation of Liability</h2>
    <p>
      GLOSTONE, PERMITBOOK.COM AND ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS
      WON’T BE LIABLE FOR:
    </p>
    <br />
    <ol>
      <li>
        ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL
        DAMAGES, OR
      </li>
      <li>
        ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY.
      </li>
    </ol>
    <br />
    <p>
      THESE EXCLUSIONS OR LIMITATIONS WILL APPLY REGARDLESS OF WHETHER OR NOT
      GLOSTONE, PERMITBOOK.COM OR ANY OF ITS AFFILIATES HAS BEEN WARNED OF THE
      POSSIBILITY OF SUCH DAMAGES.
    </p>
    <br />
    <p>
      IF YOU USE THE SERVICES FOR ANY COMMERCIAL OR BUSINESS PURPOSE, GLOSTONE,
      PERMITBOOK.COM, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS WILL HAVE NO
      LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF BUSINESS, BUSINESS
      INTERRUPTION, OR LOSS OF BUSINESS OPPORTUNITY. GLOSTONE, PERMITBOOK.COM
      AND ITS AFFILIATES AREN’T RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR
      OFFLINE, OF ANY USER OF THE SERVICES.
    </p>

    <h2>Controlling Law</h2>
    <p>
      These Terms will be governed by Oregon law and sole jurisdiction for any
      dispute shall reside in Clackamas County, Oregon.
    </p>

    <h2>Entire Agreement</h2>
    <p>
      These Terms constitute the entire agreement between you and Glostone and
      PermitBook.com with respect to the subject matter of these Terms, and
      supersede and replace any other prior or contemporaneous agreements, or
      terms and conditions applicable to the subject matter of these Terms.
      These Terms create no third party beneficiary rights.
    </p>

    <h2>Waiver, Severability &amp; Assignment</h2>
    <p>
      Glostone or PermitBook.com’s failure to enforce a provision is not a
      waiver of its right to do so later. If a provision is found unenforceable,
      the remaining provisions of the Terms will remain in full effect and an
      enforceable term will be substituted reflecting our intent as closely as
      possible. You may not assign any of your rights under these Terms, and any
      such attempt will be void. Glostone or PermitBook.com may assign its
      rights to any of its affiliates or subsidiaries, or to any successor in
      interest of any business associated with the Services.
    </p>

    <h2>Modifications</h2>
    <p>We may revise these Terms from time to time to better reflect:</p>
    <br />
    <ol>
      <li>changes to the law,</li>
      <li>new regulatory requirements, or</li>
      <li>improvements or enhancements made to our Services.</li>
    </ol>
    <br />
    <p>
      If an update affects your use of the Services or your legal rights as a
      user of our Services, we’ll notify you prior to the update's effective
      date by sending an email to the email address associated with your account
      or via an in-product notification. These updated terms will be effective
      no less than 30 days from when we notify you.
    </p>
    <br />
    <p>
      If you don’t agree to the updates we make, please cancel your account
      before they become effective. By continuing to use or access the Services
      after the updates come into effect, you agree to be bound by the revised
      Terms.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Terms'
}
</script>

<style lang="scss">
#terms {
  margin-top: 38px;
  h2 {
    margin-top: 1em;
    font-weight: 500;
  }

  li {
    margin-left: 30px;
    list-style-type: lower-alpha;
  }
}
</style>
